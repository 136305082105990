<template>
  <div style="overflow: hidden; width: 100%; padding-inline: 10px;">

    <MenuBar backgroundColor="linear-gradient(62deg, #362380 40.76%, #4AD594 91.64%)" optionColor="#FFFFFF"
     borderColor="none" clickColor="#FFFFFF" style="z-index: 2;"></MenuBar>

    <div class="heroSection">
      <div class="herotxt">
        <h6 class="dash">-</h6>
        <h6 class="txt">WE'RE HERE TO HELP!</h6>
      </div>
      <h1 class="heroHead">Gorex Solutions</h1>
    </div>

    <div class="animation-container"  v-motion-slide-visible-once-left>
      <div class="animation">
        <Vue3Lottie :animationData="playJSON" :height="400" :width="800" />
      </div>
    </div>

    <FooterBar></FooterBar>

  </div>


</template>

<script>
import { Vue3Lottie } from "vue3-lottie";
import playJSON from "../assets/Animations/coming-soon.json";
import MenuBar from './MenuBar.vue';
import FooterBar from './Footer.vue';
export default {
  name: 'SolutionsSoon',
  components: {
    Vue3Lottie,
    MenuBar,
    FooterBar
  },
  data() {
    return {
      playJSON,
    };
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heroSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(62deg, #362380 40.76%, #4AD594 91.64%);
}

.herotxt {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 50px;
}

.dash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2.5px;
  color: #FF8B00;
}

.txt {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.heroHead {
  color: #FFF;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 100px;
}

.animation-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(254deg, #F5FFF8 16.46%, rgba(245, 255, 248, 0.00) 70.4%);
}

.animation {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 50vw;
}

@media (max-width: 700px) {
  .heroHead {
    font-size: 35px;
    /* Font size for small screens (576px and below) */
  }
}
</style>