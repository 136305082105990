<template>
  <div style="overflow: hidden; width: 100%; padding-inline: 10px;">

    <MenuBar backgroundColor="linear-gradient(62deg, #362380 40.76%, #4AD594 91.64%)" optionColor="#FFFFFF"
       borderColor="none" clickColor="#FFFFFF" style="z-index: 2;"></MenuBar>

    <div class="heroSection">
      <div class="herotxt">
        <h6 class="dash">-</h6>
        <h6 class="txt">WE'RE HERE TO HELP!</h6>
      </div>
      <h1 class="heroHead">Gorex Support</h1>
    </div>

    <div class="row" style="display: flex; flex-direction: row; background-color: #FAFAFA; 
      margin: 20px;">

      <div class="col-lg-6 col-md-6 col-sm-12 col-12" style="margin-bottom: 30px; padding: 40px;">
        <img src="../assets/Images/image 2.svg" alt="" style="width: 7vw; margin-bottom: 10px;">
        <p class="textStyle">If you have any questions, comments, or concerns, please don't hesitate to get in touch
          with us.</p>
        <h6 class="heading">Go and Relax Private Limited.</h6>
        <p class="textStyle">Pakistan Address: 79 - Aurangzeb Block, Garden Town, Lahore, Pakistan.</p>
        <p class="textStyle">Saudi Arabia Address: PO Box 13317, Othman Bin Affan, Al Nada District, Riyadh, Saudi Arabia.</p>
       
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <form @submit.prevent="submitForm">

          <div class="field">
            <div class="form-group">
              <label class="label" for="name">Company Legal Name <i class="require">*</i></label>
              <input type="text" id="name" v-model="formData.companyName" class="form-control widthSet" required>
            </div>
            <div class="form-group">
              <label class="label" for="email">Business Name<i class="require">*</i></label>
              <input type="text" v-model="formData.businessName" class="form-control widthSet" required>
            </div>
          </div>

          <div class="field" >
            <div class="form-group">
              <label class="label" for="name">Type of Entity<i class="require">*</i></label>
              <!-- <input type="text" id="name" v-model="formData.name" class="form-control widthSet" required> -->
              <select class="widthSet" style="border: 1px solid #CDD6DF; padding-block: 5px; padding-inline: 5px;"
                v-model="formData.typeOfEntity" @change="handleChange">
                <option v-for="option in entityTypes" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>


            <div class="form-group" style="padding-left: 12px;">
              <label class="label" for="email">Nature of Business<i class="require">*</i></label>
              <select class="widthSet" style="border: 1px solid #CDD6DF; padding-block: 5px; padding-left: 5px; "
                v-model="formData.natureOfBusiness" @change="handleChange">
                <option v-for="option in businessNature" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>
          </div>

          <div class="field">
            <div class="form-group">
              <label class="label" for="name">City<i class="require">*</i></label>
              <input type="text" id="name" v-model="formData.city" class="form-control widthSet" required>
            </div>
            <div class="form-group">
              <label class="label" for="email">Telephone No<i class="require">*</i></label>
              <input type="text" id="email" v-model="formData.tno" class="form-control widthSet" required>
            </div>
          </div>

          <div class="field">
            <div class="form-group">
              <label class="label" for="name">Contact Person Name<i class="require">*</i></label>
              <input type="text" id="name" v-model="formData.contactName" class="form-control widthSet" required>
            </div>
            <div class="form-group">
              <label class="label" for="email">Designation<i class="require">*</i></label>
              <input type="text" id="email" v-model="formData.designation" class="form-control widthSet" required>
            </div>
          </div>

          <div class="field">
            <div class="form-group">
              <label class="label" for="name">Email<i class="require">*</i></label>
              <input type="email" id="name" v-model="formData.email" class="form-control widthSet" required>
            </div>
            <div class="form-group">
              <label class="label" for="email">Mobile No<i class="require">*</i></label>
              <input type="text" id="email" v-model="formData.mobile" class="form-control widthSet" required>
            </div>
          </div>

          <div class="form-group">
            <label class="label" for="name">Complete Mailing Address<i class="require">*</i></label>
            <input type="text" id="name" v-model="formData.address" class="form-control" required>
          </div>

          <div class="form-group">
            <label class="label" for="message">Message<i class="require">*</i></label>
            <textarea id="message" v-model="formData.message" class="form-control" rows="4" required></textarea>
          </div>

          <div style="display: flex; align-items: center; justify-content: center; border: none;">
            <button type="submit" class="buttonStyle">
              <h6 class="buttonTxt">Submit</h6>
              <img class="arrowIcon" src="../assets/Icons/arrow.svg" alt="">
            </button>
          </div>
        </form>
      </div>
    </div>



    <FooterBar></FooterBar>

  </div>

</template>

<script>
import MenuBar from './MenuBar.vue';
import FooterBar from './Footer.vue';
import { ref } from 'vue';
import axios from 'axios';
export default {
  name: 'ContactUs',
  components: {
    MenuBar,
    FooterBar
  },
  data() {
    return {
      selectedEntityType: '',
      selectedBusinessNature: '',
      entityTypes: ['Sole Proprietor', 'Partnership', 'Private Limited', 'Public Limited'],
      businessNature: ['Advertising/Marketing', 'Agriculture', 'Architecture/Design', 'Automotive', 'Beauty/Fashion', 'Construction/Real Estate',
        'Education/Training', 'Finance/Accounting', 'Food/Beverage', 'Health/Medical', 'Information Technology', 'Legal/Law',
        'Manufacturing/Production', 'Nonprofit/Charity', 'Retail/Wholesale', 'Travel/Hospitality', 'Other']
    };
  },
  // methods: {
  //   handleChange() {
  //     console.log('Selected entity type:', this.selectedEntityType);
  //     // You can perform any additional actions here when the selection changes
  //   },
  // },
  setup() {
    // Define reactive data using the ref function
    const formData = ref({
      companyName: '',
      businessName: '',
      typeOfEntity: '',
      natureOfBusiness: '',
      city: '',
      tno: '',
      contactName: '',
      designation: '',
      email: '',
      mobile: '',
      address: '',
      message: ''

    });

    // Form submission handler
    // const submitForm = () => {
      // Perform form submission logic here (e.g., send data to server)
      // console.log('Form submitted with data:', formData.value);

      // Reset form data after submission
      // formData.value = {
      //   name: '',
      //   email: '',
      //   message: ''
      // };
    // };

    return {
      formData
      // submitForm
    };
  },
  methods: {
    async submitForm() {
      try { 
        const response = await axios.post('https://be.gorex.pk/api/contact-form', this.formData);
        console.log(response.data);
        // Display success message or perform any additional actions
        alert('Form submitted successfully');

         // Reset form data after successful submission
         this.formData = {
          companyName: '',
          businessName: '',
          typeOfEntity: '',
          natureOfBusiness: '',
          city: '',
          tno: '',
          contactName: '',
          designation: '',
          email: '',
          mobile: '',
          address: '',
          message: '',
        };
      } catch (error) {
        console.error('Error submitting form:', error);
        // Display error message or perform error handling
        alert(error);
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.continuebutton:hover{
.arrowIcon{
  transform: translateX(7px);
}
}
.heroSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(62deg, #362380 40.76%, #4AD594 91.64%);
}

.herotxt {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 30px;
}

.dash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2.5px;
  color: #FF8B00;
}

.txt {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.heroHead {
  color: #FFF;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 50px;
}

.textStyle {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-group {
  margin-inline: 20px;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.label {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.widthSet {
  width: 20vw;

}

.require {
  color: #FC3B61;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttonStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding-inline: 20px;
  margin-bottom: 20px;
  width: 40vw;
  height: 40px;
  flex-shrink: 0;
  background-color: #FF8B00;
  border-radius: 50px;
  border: none;
}

.buttonTxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .heroHead {
    font-size: 35px;
    /* Font size for small screens (576px and below) */
  }

  .widthSet {
    width: 30vw;
  }
}
</style>
