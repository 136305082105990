import { createApp } from 'vue';
import {MotionPlugin} from '@vueuse/motion'
import App from './App.vue';
import router from './router.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/fonts.css'


const app = createApp(App);
console.log(app);
app.use(router);
app.use(MotionPlugin); 
app.mount('#app');
