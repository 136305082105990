<template>

  <nav :style="{background:backgroundColor}" class="mainContainer navbar navbar-expand-lg navbar-dark">


    <!-- style="width: 20%; display: flex; justify-content: center;" -->
    <router-link to="/home" class="logoContainer">
      <!-- <img :src="logoLink" class="logo" alt="Logo"> -->
      <div v-if="logoLink" class="logo"></div>
      <!-- <img v-if="isSmallScreen" src="../assets/Icons/brand-icon.svg" class="logo"> -->
      <div v-else class="logo1"></div>
    </router-link>

    <button style="background-color: linear-gradient(65deg, #362380 40%, #4AD594 91.53%)" class="navbar-toggler collapsed" type="button" data-toggle="collapse"
      data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div :style="{ display: mobile ? 'flex' : 'none'}" class="optionContainer collapse navbar-collapse">
     
      <ul class="optionSubContainer navbar-nav">
        <li class="option nav-item">
          <router-link to="/home" :style="{ color: optionColor }" class="home nav-link">Home</router-link>
        </li>
        <li class="option nav-item">
          <router-link to="/about-us" :style="{ color: optionColor }" class="home nav-link">About Us</router-link>
        </li>
        <li class="option nav-item">
          <router-link to="/partnerships-soon" :style="{ color: optionColor }"
            class=" home nav-link">Partnerships</router-link>
        </li>
        <li class="option nav-item">
          <router-link to="/services-soon" :style="{ color: optionColor }" class="home nav-link">Services</router-link>
        </li>
        <li class="option nav-item">
          <router-link to="/solutions-soon" :style="{ color: optionColor }" class="home nav-link">Solutions</router-link>
        </li>
        <li class="option nav-item">
          <router-link to="/contact-us" :style="{ color: optionColor }" class="home nav-link">Contact Us</router-link>
        </li>
      </ul>


      <div class="iconContainer">
        <!-- <img class="countryIcon" loading="lazy" alt="" src="../assets/Icons/Pakistan.svg" /> -->
    </div>

    <div class="hideContainer">

    <div type="submit" class="buttonStyle" style="margin-block: 50px;">
          <i class="buttonTxt">Login</i>
          <img style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
    </div>

    <p class="mb-2 footertxt" style="color: #fff; margin-block: 30px;">© 2023 - Gorex, All Rights Reserved.</p>

    <a class="text-white" style="margin-block: 30px;" href="https://mdbootstrap.com/">Privacy Policy</a>

<div style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center; margin-block: 30px;">
  <img src="../assets/Images/WhiteMedia.svg" alt="" />
</div>

</div>

  </div>
   

  </nav>


</template>

<script>
import { ref } from 'vue';
export default {
  name: 'MenuBar',
  props: {
    backgroundColor: String,
    optionColor: String,
    logoLink: Boolean,
    borderColor: String,
    clickColor: String
  },
  data() {
    return {
      mobile:false,
      isSmallScreen: window.matchMedia('(max-width: 991px)').matches
    };
  },
  methods: {
    toggleActive(menuItem) {
      this.activeMenu = ref(menuItem);
      console.log(this.activeMenu)
    },
    toggleMenu(){
      this.mobile = ref(!this.mobile);
      console.log(this.isSmallScreen);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hideContainer{
  display: none; 
  width: 100%;
  flex-direction: column; 
  align-items: center; 
  justify-content: center;
  padding-inline: 7vw;
  align-self: flex-start;
}
.buttonStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  /* align-self: center; */
  padding: 12px 24px;
  margin-bottom: 10px;
  border: 1px solid #FFFFFF;
  /* gap: 4px; */
  /* margin-left: 5vw; */
  width: 150px;
  border-radius: 50px;

}

.buttonTxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid borderColor;
  background-color: backgroundColor;
  /* border: 2px solid black; */
}

.logoContainer{
  width: 30%;
  height: 48.587px;
  box-sizing: border-box;
  /* border: 2px solid orange; */
}

.logo {
  width: 152px;
height: 27px;
margin-left: 30px;
background-image: url("../assets/Icons/Web-icon.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 1vw;
flex-shrink: 0;
border: none; 
}

.logo1 {
  width: 152px;
height: 27px;
margin-left: 30px;
background-image: url("../assets/Icons/logo (1).svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 1vw;
flex-shrink: 0;
/* box-sizing: border-box; */
}

.optionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
  /* box-sizing: border-box; */
  /* border: 2px solid pink; */
}

.mobileBar{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.optionSubContainer {
  width: 70%;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-between;
  /* border: 2px solid yellow; */
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px solid grey; */
}

.home {
  /* color: #171717; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: transform 0.3s ease;
  text-decoration: none;
}

.home:hover{
  transform: scale(1.1);
}


.router-link-active{
  text-decoration: underline !important;
}


.iconContainer {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end; 
  margin-block:5px;
  /* border: 2px solid blue; */
  /* padding: 2px 0px 0px; */
}

.countryIcon {
  width: 28px;
  height: 28px;
  margin-right: 30px;
}

.active {
   text-decoration: underline;
  /* .home {
    color: clickColor !important;
     border-bottom-color: #FF8B00 !important; 
  } */
}

@media (max-width: 1069px) {
  .optionSubContainer{
    width: 90%;
  }

  .iconContainer{
    width: 10%;
  }
}

@media (max-width: 991px) {
  .mainContainer{
    background: linear-gradient(65deg, #362380 40%, #4AD594 91.53%) !important;
  }
  .logo{
    background-image: url("../assets/Icons/brand-icon.svg");
    width: 47.849px;
height: 48.587px;
margin-left: 5px;
padding-top: 5px;
flex-shrink: 0;
  }

  .logo1{
    background-image: url("../assets/Icons/brand-icon.svg");
    width: 47.849px;
height: 48.587px;
margin-left: 5px;
padding-top: 5px;
flex-shrink: 0;
  }

  .optionContainer{
    
    /* flex-direction: column; */
    /* align-items: end; */


    flex-direction: column;
    /* border: 2px solid white;  */
    width: 70%; 
    /* height: auto; */
    position: relative; 
    align-items: end;
    background:linear-gradient(46deg, #362380 0.56%, #362380 3.88%, #000 95.44%) !important;
    
    /* justify-content: end; */
  }

  .optionSubContainer{
    width: 90%;
    margin-top: 10vw;
    /* background:linear-gradient(46deg, #362380 0.56%, #362380 3.88%, #000 95.44%) !important; */
  }

  .iconContainer{
    width: 90%;
    position: absolute;
    top: 20;
    /* margin-left: 20px; */
    justify-content: flex-start;
    /* background:linear-gradient(46deg, #362380 0.56%, #362380 3.88%, #000 95.44%) !important; */
  }
  .mobileBar{
    flex-direction: column;
    /* border: 2px solid white;  */
    width: 70%; 
    height: 100%;
    position: relative; 
    align-items: end;
    background:linear-gradient(46deg, #362380 0.56%, #362380 3.88%, #000 95.44%) !important;
  }
  .option{
    margin-bottom: 5px;
  }
  .home {
    color: #FFFFFF !important;
  }

  .buttonStyle{
    display: flex;
  }
  .hideContainer{
    display: flex;
  }
}
</style>
