import { createRouter, createWebHistory } from 'vue-router';
import RegionSelection from './Pages/RegionSelection.vue';
import HomeComponent from './components/Home.vue';
import AboutUs from './components/AboutUs.vue';
import ContactUs from './components/ContactUs.vue';
import PartnershipsSoon from './components/Partnerships.vue';
import ServicesSoon from './components/Services.vue';
import SolutionsSoon from './components/Solutions.vue';

  const routes = [ 
    {
      path: '/',
      redirect: '/region-selection' 
    },
    {
      path: '/region-selection',
      name: 'Region Selection',
      component: RegionSelection
    },
    { 
      path: '/home', 
      component: HomeComponent 
    },
    { 
      path: '/about-us', 
      component: AboutUs 
   },
    {
      path: '/Contact-us', 
      component: ContactUs 
    },
    { 
      path: '/partnerships-soon', 
      component: PartnershipsSoon 
    },
    { 
      path: '/services-soon', 
      component: ServicesSoon 
    },
    { 
      path: '/solutions-soon', 
      component: SolutionsSoon 
    },
  ];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;