<template>

  <div class="mainContainer">

    <div class="subContainer">

      <div class="textContainer">

      <img class="icon" src="../assets/Icons/Web-icon.svg" alt="">

      <h1 class="heading">Gorex Fulet Wallet</h1>

      <p class="subHeading">Transforming Your Fuel Experience for Experience</p>

      <div class="buttonGroup" role="group" aria-label="Basic example">
        <button :class="{ selected: selectedButton === 'Saudia' }" @click="toggleButton('Saudia')"
          class="selectionButton" type="button">
          <div class="innerButton">
            <p class="buttonHead">Saudi Arabia</p>
            <img src="../assets/Icons/Saudia.svg" alt="">
          </div>
          <p class="Txt">Contribute to environmental sustainability.</p>
        </button>

        <button :class="{ selected: selectedButton === 'Pakistan' }" @click="toggleButton('Pakistan')" type="button"
          class="selectionButton">
          <div class="innerButton">
            <p class="buttonHead">Pakistan</p>
            <img src="../assets/Icons/Pakistan.svg" alt="">
          </div>
          <p class="Txt">Contribute to environmental sustainability.</p>
        </button>
      </div>

      <a @click="proceed" :href="route" type="button" class="continuebutton">
        <h6 class="buttonTxt">Continue</h6>
        <img class="arrowIcon" src="../assets/Icons/arrow.svg" alt="">
      </a>

    </div>

    </div>

    <div class="background-div">
    <!-- <img style="width: 100%; height: 100vh" src="../assets/Images/Frame.svg" alt=""> -->
    </div>


  </div>

</template>

<script>
export default {
  name: 'RegionSelection',

  data() {
    return {
      selectedButton: 'Pakistan',
      route: 'Pakistan',
    };
  },
  methods: {
    toggleButton(buttonId) {
      this.selectedButton = buttonId;
    },
    proceed() {

      if (this.selectedButton == 'Pakistan') {
        this.route = '/home';

      } else if (this.selectedButton == 'Saudia') {
        this.route = 'https://gorex.ai/';
      }
      console.log(this.route);
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.continuebutton:hover{
.arrowIcon{
  transform: translateX(7px);
}
}
.textContainer{
  margin-inline: 5vw; 
  margin-block: 6vw; 
  display: flex; 
  flex-direction: column;
}
.mainContainer{
  width: 100%; 
  height: 100vh; 
  display: flex; 
  flex-direction: row; 
  padding-inline: 10px;
}
.subContainer{
  width: 50%; 
  height: 100vh; 
}
.background-div {
  width: 50%;
  background-image: url("../assets/Images/Frame.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; 
}
.icon{
  width: 152px;
height: 27px;
margin-bottom: 20px;
}

.buttonGroup{
  display: flex; 
  flex-direction: column; 
  margin-block: 20px;
}
.innerButton{
  width: 95%; 
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  justify-content: space-between; 
  padding-inline: 10px;
}
.Txt {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.heading {
  color: #171717;
  font-family: Poppins;
  font-size: 27.268px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 52.833px; */
  text-transform: capitalize;
  margin-block: 1vw;
}

.subHeading {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 15.339px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block: 1vw;
}

.selectionButton {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 20px 10px;
  justify-content: center;
  align-items: flex-start;
  /* gap: 100px; */
  border-radius: 24px;
  border: 'none';
  margin-bottom: 1vw;
  background: linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%);
}

.buttonHead {
  color: #171717;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 166.667% */
  text-transform: capitalize;
}

.selected {
  border: 3px solid #FF8B00;

  .buttonHead {
    color: #FF8B00 !important;
  }
}


.continuebutton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* align-self: center; */
  padding-inline: 20px;
  margin-bottom: 20px;
  width: 32vw;
  height: 5vh;
  /* flex-shrink: 0; */
  background-color: #FF8B00;
  border-color: #FF8B00;
  border-radius: 50px;
  text-decoration: none;
}

.buttonTxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .subContainer {
    width: 100%;
    height: auto;
    /* Font size for small screens (576px and below) */
  }
  .textContainer{
    margin-block: 10vh;
  }
  .background-div {
    width: 0;
    height: 0;
    display: none;
  }

  .heading{
    font-size: 24px;
    margin-block: 15px;
  }

  .subHeading{
    font-size: 16px;
    margin-block: 15px;

  }

  .selectionButton{
    width: 95%;
    margin-block: 15px;
  }

  .continuebutton{
    width: 83vw;
  }

  .buttonHead{
    font-size: 18px;
  }

  .buttonTxt{
    font-size: 15px;
  }
}
</style>
