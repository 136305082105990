<template>

  <div class="container-fluid" style="background: linear-gradient(211deg, #000 39.56%, #362380 100%);">

    <footer class="text-center text-lg-start border border-white mt-xl-5 pt-4">
      <!-- Grid container -->
      <div class="container p-4">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 ">
            <h5 class="text-uppercase mb-2 heading">Gorex</h5>

            <ul class="list-unstyled mb-10">
              <li>
                <router-link to="/about-us" class="text-white footertxt">About Us</router-link>
                <!-- <a href="/about-us" class="text-white footertxt">About Us</a> -->
              </li>
              <li>
                <router-link to="/partnerships-soon" class="text-white footertxt">Partnerships</router-link>
                <!-- <a href="/partnerships-soon" class="text-white footertxt">Partnerships</a> -->
              </li>
              <li>
                <router-link to="/services-soon" class="text-white footertxt">Services</router-link>
                <!-- <a href="/services-soon" class="text-white footertxt">Services</a> -->
              </li>
              <li>
                <router-link to="/solutions-soon" class="text-white footertxt">Solutions</router-link>
                <!-- <a href="/solutions-soon" class="text-white footertxt">Solutions</a> -->
              </li>
              <li>
                <router-link to="/contact-us" class="text-white footertxt">Contact Us</router-link>
                <!-- <a href="/contact-us" class="text-white footertxt">Contact Us</a> -->
              </li>
            </ul>

            <div class="mt-20"
              style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">

              <img src="../assets/Icons/ios.svg" alt="">
              <img src="../assets/Icons/android.svg" alt="">

            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 ">
            <h5 class="text-uppercase mb-2 heading">Services</h5>

            <ul class="list-unstyled">
              <li>
                <a href="/home" class="text-white footertxt">Coming Soon</a>
              </li>

            </ul>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <h5 class="text-uppercase mb-2 heading">Any Questions?</h5>

            <h6 class="mb-2 footertxt">Go and Relax Private Limited.</h6>

            <p class="mb-6 footertxt">Pakistan Address: 79 - Aurangzeb Block, Garden Town, Lahore, Pakistan.</p>
            <p class="mb-6 footertxt">Saudi Arabia Address: PO Box 13317, Othman Bin Affan, Al Nada District, Riyadh, Saudi Arabia.</p>
            



          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0" style="background: linear-gradient(27deg, #362380 3.01%, #000 88.15%); width: 600px;
                height: 260px; padding-block: 10px; border-radius: 15px;">
            <h5 class="text-uppercase mb-2 heading">Subscribe to our newsletter</h5>
            <p class="mb-4 footertxt">Get exclusive access to promotions, updates, and King and the industry insights
              delivered straight to your inbox.</p>
            <div class="form-outline form-white mb-4">
              <input type="email" id="form5Example2" placeholder="Email address..." class="form-control mb-4" />
              <button type="submit" class="btn btn-outline-white btn-block "
                style="color: #FFF; border: 1px solid white;">Subscribe</button>
            </div>

          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3 border-top border-white"
        style="width: 100%; display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">
        <img loading="lazy" alt="" src="../assets/Icons/logo.svg" />

        <!-- <p class="mb-2 footertxt ml-5">© 2023 - Gorex, All Rights Reserved.</p> -->

        <!-- <a class="text-white mb-2" href="https://mdbootstrap.com/">Privacy Policy</a> -->

        <div style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">
          <img src="../assets/Images/WhiteMedia.svg" alt="" />
        </div>

      </div>
      <!-- Copyright -->
    </footer>

  </div>
  <!-- End of .container -->

</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-white {
    border-color: none !important;
}
.border{
  border: none !important;
}
.heading {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footertxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
